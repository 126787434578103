import * as React from 'react';
import styled from 'styled-components';

// Contexts
import { GlobalContext } from '../contexts/GlobalContext';

// Components
import { ContentContainer } from '../Components/Common/ContentContainer';
import { HeroBanner } from '../Components/Common/HeroBanner';
import { RequestForm } from '../Components/Common/RequestForm';
import { Link } from '../Components/Common/Styles/Typography/Links/Link';

const Wrapper = styled(ContentContainer)`
    padding: 64px 0; 
    display: flex;
    flex-wrap: wrap;


    @media (min-width: ${props => props.theme.screensizes.medium}) {
        flex-basis: 60%;
        max-width: initial;
    }

    > * {
        flex-basis: 100%;
        width: 100%;
    }

    > :first-child {
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            flex-basis: 60%;
            max-width: initial;
        }
    }

    > :last-child {
        margin: 32px auto 0;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            margin-top: 0;
            flex-basis: 40%;
            padding: 0 2vw;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }
`;

const AddressBlock = styled.div`
    padding: 32px;
    border: 1px solid ${ props => props.theme.colors.casperGrey };
    margin: 0 auto 32px;
    max-width: 375px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        max-width: initial;
    }

    .address-block__title,
    a, p {
        font-family: ${ props => props.theme.fonts.din };
        text-align: center;
        font-weight: 100;
        margin-bottom: 0;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            text-align: left;
        }
    }

    a, p {
        font-size: 20px;
        display: block;
    }

    .address-block__title {
        font-size: 24px;
        margin-bottom: 16px;
        font-family: ${ props => props.theme.fonts.din };
        font-weight: bold;
    }
`;

const ContactUsTemplate: React.FC <{}> = () => {

    const { siteDetails } = React.useContext(GlobalContext).themeSettings

    return (
        <>
            <HeroBanner
                data={{heading: 'We\'d love to hear from you', subHeading: 'Contact Us', blurb: '', buttonText: '', buttonLink: '', bulletinText: '', bulletinLink: { target: '', title: '', url: '' }}}
                minHeight={ 275 }
            />
            <Wrapper>
                <RequestForm
                    formId={ 2 }
                    formTitle="Ready to Talk?"
                    formTagline="Complete the form below and a member of our team will be in touch shortly to discuss your requirements."
                />
                <article>
                    <AddressBlock>
                        <h2 className="address-block__title">Sales & Customer Service</h2>
                        { siteDetails?.phoneNumber_displayReady && (
                            <Link text={ `Call us on: ${siteDetails.phoneNumber_displayReady}` } link={ `tel:${siteDetails.phoneNumber_displayReady}`} />
                        )}
                        { siteDetails?.emailAddress && (
                            <Link text={ `Email us on: ${siteDetails.emailAddress}` } link={ `mailto:${siteDetails.emailAddress}`} />
                        )}
                    </AddressBlock>

                    <AddressBlock>
                        <h2 className="address-block__title">Postal Address</h2>
                        { siteDetails?.address && (
                            <p>{ siteDetails.address }</p>
                        )}
                    </AddressBlock>
                </article>
            </Wrapper>
        </>
    )
}
export default ContactUsTemplate;